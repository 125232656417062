(function ()
{
    'use strict';

    BusinessPlaceController.$inject = ["$scope", "Upload", "$state", "$translate", "BusinessPlace", "MediaType", "MediaImages", "UsersAssigned", "PromotersAssigned", "BusinessPlaceService", "BusinessPlaceUtils", "BusinessCodeService", "Business", "BusinessMediaUtils", "Application", "Category", "ApplicationService", "PartyDays", "$timeout", "$mdDialog", "$mdToast", "$filter", "Principal", "DateUtils"];
    angular
        .module('app.businesses')
        .controller('BusinessPlaceController', BusinessPlaceController);

    /** @ngInject */
    function BusinessPlaceController($scope, Upload, $state, $translate, BusinessPlace, MediaType, MediaImages, UsersAssigned, PromotersAssigned, BusinessPlaceService, BusinessPlaceUtils, BusinessCodeService, Business, BusinessMediaUtils, Application, Category, ApplicationService, PartyDays, $timeout, $mdDialog, $mdToast, $filter, Principal, DateUtils) {

        const FRONT_ICON_DISBLED = "icon-comment-processing-outline";
        const FRONT_ICON_ENABLED = "icon-comment-processing";
        const MORE_ICON_DISBLED = "icon-comment-alert-outline";
        const MORE_ICON_ENABLED = "icon-comment-alert";
        const WRITE_ICON_DISBLED = "icon-pencil-box-outline";
        const WRITE_ICON_ENABLED =  "icon-pencil-box";

        const COLUMNS = 2;

        var vm = this;

        if (BusinessPlace.enabled===undefined) $state.go("home");

        // default values
        vm.business = BusinessPlace;
        vm.imageTypes = MediaType.data;
        vm.partyDays = PartyDays;

        vm.imageType = "IMAGE_EVENTS";
        vm.punchOffer = true;
        vm.maxFiles = false;
        vm.authorities = ['', 'ROLE_PROMOTOR', 'ROLE_QR_READER', 'ROLE_QR_CHECK', 'ROLE_QR_DAR_VIP',  'ROLE_QR_BONUS', 'ROLE_QR_BAR', 'ROLE_BUSINESS_REQUEST'];

        vm.listHours = [];
        for (var i = 0; i < 30; i++) {
            var hour = i < 10 ? "0" + i : i < 24 ? i : "0" + (i - 24);
            vm.listHours.push({id: (i * 10), value: hour + ":00" + ((i > 24) ? " +1d" : "")});
            vm.listHours.push({id: (i * 10) + 3, value: hour + ":30" + ((i > 24) ? " +1d" : "")});
        }
        vm.listHours.push({id: 300, value:"06:00"});

        vm.editBusiness=(vm.business.id!==undefined);
        Principal.identity().then(function(account) {
            vm.admin = Principal.hasAnyAuthority(["ROLE_ADMIN"]);
        });

        vm.staticWeb = "https://static.vipcard.group/buttons/";
        vm.staticURL = "https://static.vipcard.group/buttons/";

        vm.applications = Application.query();
        vm.categories = Category.query();
        vm.businesses = Business.query();

        vm.images = MediaImages;
        vm.selectedCategories = [];

        vm.users = UsersAssigned;
        vm.promoters = PromotersAssigned;

        vm.searchedUsers = [];
        vm.searchUser = "";
        vm.searchedProms = [];
        vm.searchProm = "";

        vm.expiration = vm.business.defaultExpirationDays;
        vm.frontal = true;

        vm.startFrom=null;
        vm.codesPrice = true;
        vm.codesPrice2 = true;
        vm.uploading = false;
        vm.selectedImages = [];

        vm.colorOptions = {
            history: false,
            rgb: false,
            hsl: false
        };

        if (!vm.editBusiness) {
            vm.business.backContent = $filter('translate')('BP.BACK_CONTENT_DEFAULT');
        } else {
            vm.business.punchEndDate = DateUtils.convertDateTimeFromServer(vm.business.punchEndDate);
            vm.business.punchEndDate2 = DateUtils.convertDateTimeFromServer(vm.business.punchEndDate2);
        }

        // Data
        vm.taToolbar = [
            ['quote', 'bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear', 'html'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent', 'wordcount', 'charcount']
        ];

        vm.categoriesSelectFilter = '';
        vm.ngFlowOptions = {
            // You can configure the ngFlow from here
            /*
             target                   : 'api/media/image',
             chunkSize                : 15 * 1024 * 1024,
             maxChunkRetries          : 1,
             simultaneousUploads      : 1,
             testChunks               : false,
             progressCallbacksInterval: 1000
           */
        };
        vm.ngFlow = {
            // ng-flow will be injected into here through its directive
            flow: {}
        };
        vm.dropping = false;
        vm.imageZoomOptions = {};

        // Methods
        vm.changePosition = changePosition;
        vm.changeImagePosition = changeImagePosition;
        vm.writeValue = writeValueLink;
        vm.writeTitle = writeTitle;
        vm.toFront = sendLinkToFront;
        vm.toMore = sendLinkToMoreInfo;
        vm.onChangeHour = onChangeHour;
        vm.addHour = addHour;
        vm.deleteHour = deleteHour;

        vm.filterCategory = filterCategory;
        vm.generateCodes = generateCodes;
        vm.saveCode = saveCode;
        vm.showCodes = showCodes;

        vm.saveBusiness = saveBusiness;
        vm.gotoBusinesses = gotoBusinesses;
        vm.fileAdded = fileAdded;
        vm.uploadLogo = uploadLogo;
        vm.uploadImage = uploadImage;
        vm.editTitle = editTitle;
        vm.deleteImage = deleteImage;

        vm.uploadPartyImage = uploadPartyImage;
        vm.editPartyTitle = editPartyTitle;
        vm.editPartyDescription = editPartyDescription;
        vm.deleteParty = deleteParty;

        vm.isFormValid = isFormValid;

        vm.addUser = addUser;
        vm.removeUser = removeUser;
        vm.changeUserRol = changeUserRol;
        vm.addPromoter = addProm;
        vm.removePromoter = removeProm;
        vm.editPromoCode = editPromoCode;
        vm.editPromoPhone = editPromoPhone;

        vm.toggleImage = toggleImage;
        vm.existImage = existImage;
        vm.deleteSelectedImages = deleteSelectedImages;
        vm.changeTitle = changeTitle;
        vm.checkMaxFiles = checkMaxFiles;
        vm.imageByApp = imageByApp;

        // Methods
        init();

        $scope.$watchCollection("[vm.business.cardTemplate.cardBackColor, vm.business.cardTemplate.fontTitleBackColor, vm.business.cardTemplate.fontCardBackColor]", function (newValue, oldValue) {
            redrawBack();
        });

        $scope.$watchCollection("[vm.business.cardTemplate.cardColor, vm.business.cardTemplate.cardLineColor, vm.business.cardTemplate.fontCardColor, vm.business.cardTemplate.fontTitleColor, vm.business.cardTemplate.fontNumberColor]", function (newValue, oldValue) {
            redrawFrontal();
        });

        $scope.$watch("vm.imageType", function(newValue, oldValue) {
            vm.selectedImages = [];
            checkMaxFiles();
        });

        function arrayContains(array, appId) {
            var exist = false;
            for (var i = 0, len = array.length; i < len; i++) {
                if (array[i]==appId) {
                    exist=true;
                    break;
                }
            }
            return exist;
        }

        function redrawBack() {
            var canv = $('#backCardCanvas');
            if (canv.length > 0) {
                var ctx = canv[0].getContext('2d');
                drawRect2(ctx, vm.business.cardTemplate.cardBackColor, vm.business.cardTemplate.fontTitleBackColor, vm.business.cardTemplate.fontCardBackColor);
            }
        }

        function redrawFrontal() {
            var canv = $('#frontCardCanvas');
            if (canv.length > 0) {
                var ctx = canv[0].getContext('2d');
                drawRect1(ctx, vm.business.cardTemplate.cardColor, vm.business.cardTemplate.cardLineColor, vm.business.cardTemplate.fontCardColor, vm.business.cardTemplate.fontTitleColor, vm.business.cardTemplate.fontNumberColor);
            }
        }

        function drawRect1(ctx, cl1, cl2, cl3, cl4, cl5) {
            ctx.fillStyle = cl1;
            ctx.fillRect(10,10,390,150);

            ctx.fillStyle = cl2;
            ctx.fillRect(10,150,390,40);

            ctx.font="20px Verdana";
            var cliente = "JUAN GUTIERREZ GARCIA";
            ctx.fillStyle = cl4;
            ctx.fillText(cliente, 20, 178);

            ctx.fillStyle = cl1;
            ctx.fillRect(10,190,390,80);

            ctx.font="18px Verdana";
            var numero = "SR456564"
            ctx.fillStyle = cl5;
            ctx.fillText(numero, 150, 215);

            var direccion = vm.business.address || "Sin dirección";
            ctx.font="12px Verdana";
            ctx.fillStyle = cl3;
            ctx.fillText(direccion, 100, 245);
        }

        function drawRect2(ctx, cl0, cl1, cl2) {
            ctx.fillStyle = cl0;
            ctx.fillRect(10,10,390,270);

            ctx.font="18px Verdana";
            var nombre = vm.business.cardName;
            ctx.fillStyle = cl1;
            ctx.fillText(nombre, 20, 50);

            ctx.font="12px Verdana";
            var texto = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, ",
                         "sed do eiusmod tempor incididunt ut labore et dolore magna ",
                         "aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
                         "ullamco laboris nisi ut aliquip ex ea commodo consequat."];
            var yPos=80;
            for(var i=0;i<4;i++) {
                ctx.fillStyle = cl2;
                ctx.fillText(texto[i], 20, yPos);
                yPos+=20;
            }

        }

        function onChangeHour(day) {
            day.opening = day.open ? "abierto" : "cerrado";
            if (day.open && !day.hours) {
                day.hours = [];
                addHour(day);
            }
        }

        function addHour(day) {
            day.hours.push({o:0, c:0});
        }

        function deleteHour(day, index) {
            day.hours.splice(index, 1);
        }
        /**
         * Initialize
         */
        function init()
        {
            if ($state.current.name==='app.businesses.businessCode.detailCode') {
                vm.maxFront = 0;
                vm.maxMore = 0;
                vm.links = [];
                vm.totalLinksFront=0;
            } else {

                ApplicationService.getAllLinks().then(function (data) {
                    vm.maxFront = data.maxFront;
                    vm.maxMore = data.maxMore;
                    vm.links = [], vm.extraLinks = [];

                    var links = [], extraLinks = [];
                    vm.totalLinksFront = (vm.business.links.frontLinks) ? vm.business.links.frontLinks.length : 0;

                    var urlSubs = "_vc";
                    switch (vm.business.applicationId) {
                        case 8:
                            urlSubs = "_in";
                            break;
                        case 11:
                            urlSubs = "_nc";
                            break;
                        case  12:
                            urlSubs = "_tt";
                            break;
                        case  13:
                            urlSubs = "_bs";
                            break;
                        case  3:
                            urlSubs = "_ce";
                            break;
                        case  5:
                            urlSubs = "_st";
                            break;
                        case  7:
                            urlSubs = "_wc";
                            break;
                        case  15:
                            urlSubs = "_sh";
                            break;
                        case  16:
                            urlSubs = "_lf";
                            break;
                        case  4:
                            urlSubs = "_sc";
                            break;
                        case  10:
                            urlSubs = "_se";
                            break;
                        case  9:
                            urlSubs = "_rc";
                            break;
                        case  17:
                            urlSubs = "_eg";
                            break;
                        case  18:
                            urlSubs = "_wn";
                            break;
                        case  19:
                            urlSubs = "_pa";
                            break;
                        case 20:
                            urlSubs = "_ge";
                            break;
                    }

                    angular.forEach(data.links, function (link) {

                        link.frontIcon = FRONT_ICON_DISBLED;
                        link.moreIcon = MORE_ICON_DISBLED;
                        link.valueIcon = WRITE_ICON_DISBLED;
                        link.hideValue = link.type == "webview";
                        link.disableFront = inFrontLink(link.tags);

                        if (arrayContains(link.availableApps, vm.business.applicationId) || link.availableApps.length==0 || !vm.editBusiness) {

                            angular.forEach(vm.business.links.frontLinks, function (front, index) {
                                if (link.id == front.type) {
                                    link.frontIcon = FRONT_ICON_ENABLED;
                                    link.valueIcon = WRITE_ICON_ENABLED;
                                    link.value = front.value;
                                    link.orderIcon = "icon-numeric-" + (index + 1) + "-box";
                                    link.order = (index + 1);
                                }
                            });
                            angular.forEach(vm.business.links.moreInfo, function (more) {
                                if (link.id === more.type) {
                                    link.moreIcon = MORE_ICON_ENABLED;
                                    link.valueIcon = WRITE_ICON_ENABLED;
                                    link.value = more.value;

                                    if (link.type.indexOf("EXTRA_") !== -1) {
                                        link.name = more.title;
                                    }
                                }
                            });

                            if (link.availableApps.length>0) {
                                if (link.type.indexOf("EXTRA_") === -1) links.push(link);
                                else {
                                    link.imageUrl = link.imageUrl.replaceAll("_vc", urlSubs);
                                    extraLinks.push(link);
                                }
                            } else if (link.frontIcon === FRONT_ICON_ENABLED || link.moreIcon === MORE_ICON_ENABLED) links.push(link);

                        }


                    });

                    var length = links.length;
                    var itemsCol = Math.round(length / COLUMNS);
                    for (var iter = 0; iter < COLUMNS; iter++) {
                        var tmp = [];
                        for (var i = iter * itemsCol; i < (iter * itemsCol) + itemsCol; i++) {
                            if (links[i] !== undefined) tmp.push(links[i]);
                        }
                        vm.links.push(tmp);
                    }

                    length = extraLinks.length;
                    itemsCol = Math.round(length / COLUMNS);
                    for (var iter = 0; iter < COLUMNS; iter++) {
                        var tmp = [];
                        for (var i = iter * itemsCol; i < (iter * itemsCol) + itemsCol; i++) {
                            if (extraLinks[i] != undefined) tmp.push(extraLinks[i]);
                        }
                        vm.extraLinks.push(tmp);
                    }

                    if (!vm.business.other || !vm.business.other.openHours) {
                        vm.business.other = { openHours: [
                            {day: 1, open: false},
                            {day: 2, open: false},
                            {day: 3, open: false},
                            {day: 4, open: false},
                            {day: 5, open: false},
                            {day: 6, open: false},
                            {day: 7, open: false}
                        ]};
                    }
                });

                for (var day=1; day<8; day++) {
                    var found = false;
                    for (var party = 0; party < vm.partyDays.length; party++) { if (vm.partyDays[party].day===""+day) found = true;}
                    if (!found) vm.partyDays.push({id: null, placeId: vm.business.id, day: ""+day, dayTitle: "", descriptionHTML: "", image: ""});
                }
            }

            if (vm.editBusiness) {
                BusinessPlaceUtils.countCards(vm.business.id).then(function (data) {
                    vm.business.openCards = data.openCards;
                    vm.business.activeCards = data.activeCards;
                });

                if (vm.business.categories && vm.business.categories !== "") {
                    angular.forEach(vm.business.categories.split("#"), function (i, e) {
                        if (i.trim() != "") vm.selectedCategories.push(i);
                    });
                }
            }

            $timeout(function() {
                redrawFrontal();
                redrawBack();
            }, 500);

            checkMaxFiles();
        }

        function inFrontLink(options) {
            var disable = true;
            angular.forEach(options, function(option) {
                if (option.name=="front") disable = false;
            });
            return disable;
        }

        function filterCategory(appId, countryId) {
            return function(item) {
                return (item.applicationId==appId && item.countryId==countryId);
            };
        }

        function changePosition(link,ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.position'))
                .textContent($translate.instant('vipWebApp.business.dialog.positionLink'))
                .placeholder('Número')
                .ariaLabel("Número")
                .initialValue(link.order)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                removeLinkPosition(link);
                recalculateIconOrder(result-1, false);
                addLinkPosition(link, result);
            }, function() {});
        }

        function changeImagePosition(image,ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.position'))
                .textContent($translate.instant('vipWebApp.business.dialog.positionImage'))
                .placeholder('Número')
                .ariaLabel("Número")
                .initialValue(image.position)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                recalculateImage(image, parseInt(result));
            }, function() {});
        }


        function changeTitle(ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.image'))
                .textContent($translate.instant('vipWebApp.business.dialog.editTitleM'))
                .placeholder('Descripción')
                .initialValue("")
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                var title = (result=="") ? "NO_TEXT" : result ;
                BusinessMediaUtils.updateMultipleTitle(vm.selectedImages, title).then(function(data) {
                    angular.forEach(vm.images, function(image) {
                        if (vm.selectedImages.indexOf(image.id)>=0) {
                            image.title = result;
                        }
                    });
                });
            }, function() {});
        }

        function editTitle(image,ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.image'))
                .textContent($translate.instant('vipWebApp.business.dialog.editTitle'))
                .placeholder($translate.instant('vipWebApp.business.dialog.description'))
                .initialValue(image.title)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                var title = (result=="") ? "NO_TEXT" : result ;
                BusinessMediaUtils.updateTitle(image.id, title).then(function(data) {
                   image.title = result;
                });
            }, function() {});
        }

        function deleteSelectedImages(ev) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('vipWebApp.business.confirmation.titleM'))
                .htmlContent($translate.instant('vipWebApp.business.confirmation.contentM'))
                .ariaLabel('Approve delete')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function(result) {
                BusinessMediaUtils.deleteMultipleMedia(vm.selectedImages).then(function(data) {
                    var i = 200;
                    angular.forEach(vm.images, function(image) {
                        if (vm.selectedImages.indexOf(image.id)>=0) {
                            sortImages(image, i++);
                        }
                    });
                    i = vm.images.length;
                    while (i--) { if (vm.images[i] && vm.selectedImages.indexOf(vm.images[i].id)>=0) vm.images.splice(i, 1);}
                    vm.selectedImages = [];
                    checkMaxFiles();
                });
            }, function() {});
        }


        function deleteImage(image, ev) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('vipWebApp.business.confirmation.title'))
                .htmlContent($translate.instant('vipWebApp.business.confirmation.content'))
                .ariaLabel('Approve delete')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function(result) {
                BusinessMediaUtils.deleteMedia(image.id).then(function(data) {
                    sortImages(image, 100);
                    var i = vm.images.length;
                    while (i--) { if (vm.images[i] && vm.images[i].id == image.id) vm.images.splice(i, 1);}
                    checkMaxFiles();
                });
            }, function() {});
        }

        /**
         * Upload
         * Automatically triggers when files added to the uploader
         */
        function uploadPartyImage(file, party, field) {
            if (file.name!==undefined) {
                vm.uploading = true;
                Upload.upload({
                    url: '/api/party-days/upload/media',
                    data: {file: file, 'id': vm.business.id, 'day': party.day}
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    if (data.id!=null) {
                        party.id = data.id;
                        party.image = data.image;
                    }
                    vm.uploading = false;
                });
            }
        }

        function editPartyTitle(party, ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.image'))
                .textContent($translate.instant('vipWebApp.business.dialog.editTitle'))
                .placeholder($translate.instant('vipWebApp.business.dialog.description'))
                .initialValue(party.dayTitle)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                party.dayTitle = result;
                party.updated = true;
            }, function() {});
        }

        function editPartyDescription(party, ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.image'))
                .textContent($translate.instant('vipWebApp.business.dialog.editTitle'))
                .placeholder($translate.instant('vipWebApp.business.dialog.description'))
                .initialValue(party.descriptionHTML)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                party.descriptionHTML = result;
                party.updated = true;
            }, function() {});
        }

        function deleteParty(party, ev) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('vipWebApp.business.confirmation.title'))
                .htmlContent($translate.instant('vipWebApp.business.confirmation.content'))
                .ariaLabel('Approve delete')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            var id = party.id;
            $mdDialog.show(confirm).then(function(result) {
                BusinessPlaceUtils.deletePartyDayFromPlace(vm.business.id, id).then(function(data) {
                    party.image=null; party.dayTitle="";party.descriptionHTML=""; party.id = null; party.updated = false;
                });
            }, function() {});
        }


        function writeValueLink(link, ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.field'))
                .textContent($translate.instant('vipWebApp.business.dialog.linkValue'))
                .placeholder('Valor')
                .ariaLabel("Valor")
                .initialValue(link.value)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                link.value=result;
                link.valueIcon = (result.trim()=="") ? WRITE_ICON_DISBLED : WRITE_ICON_ENABLED;
            }, function() {});
        }
        function writeTitle(link, ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.tile'))
                .textContent($translate.instant('vipWebApp.business.dialog.titleValue'))
                .placeholder('Valor')
                .ariaLabel("Valor")
                .initialValue(link.name)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                link.name=result;
            }, function() {});
        }



        function sendLinkToFront(link) {
            if (link.frontIcon===FRONT_ICON_DISBLED) {
                if (vm.totalLinksFront>=vm.maxFront) {
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent($translate.instant('vipWebApp.business.message.noMoreLinks'))
                            .position('top right')
                    );
                } else {
                    addLinkPosition(link, vm.totalLinksFront+1);
                    checkPunchWelcome(link);
                }
            } else {
                removeLinkPosition(link);
            }
        }

        function sendLinkToMoreInfo(link) {
            link.moreIcon = (link.moreIcon==MORE_ICON_DISBLED) ? MORE_ICON_ENABLED : MORE_ICON_DISBLED;
            checkPunchWelcome(link);
        }

        function checkPunchWelcome(link) {
            if (link.type==="BONUS_PUNCH" && (!vm.business.punchName || vm.business.punchName==="")) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.business.message.punchName'))
                        .position('top right')
                );

            } else if (link.type==="WELCOME_OFFER" && (!vm.business.welcomeProductName || vm.business.welcomeProductName==="")) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.business.message.welcomeName'))
                        .position('top right')
                );
                vm.business.welcomeProductName="Regalo de bienvenida";
            }
        }
        function addLinkPosition(link, position) {
            vm.totalLinksFront++;
            link.frontIcon=FRONT_ICON_ENABLED;
            link.order=position;
            link.orderIcon = "icon-numeric-" + (link.order) + "-box";
        }

        function removeLinkPosition(link) {
            link.frontIcon=FRONT_ICON_DISBLED;
            recalculateIconOrder(link.order, true);
            vm.totalLinksFront--;
            link.orderIcon=null;
            link.order=null
        }

        function recalculateIconOrder(order, remove) {
            angular.forEach(vm.links, function(links) {
               angular.forEach(links, function(link) {
                  if (link.order && link.order!=null) {
                      if (link.order>order) {
                          (remove) ? link.order-- : link.order++;
                          link.orderIcon = "icon-numeric-" + (link.order) + "-box";
                      }
                  }
               });
            });
        }

        function recalculateImage(image, newPosition) {
            BusinessMediaUtils.updatePosition(image.id, newPosition).then(function(data) {
                sortImages(image, newPosition);
            });
        }

        function sortImages(image, newPosition) {
            angular.forEach(vm.images, function(media){
                if (media.type==vm.imageType) {
                    if (media.position >= newPosition && media.position < image.position) media.position++;
                    else if (media.position <= newPosition && media.position > image.position) media.position--;
                }
            });
            image.position = newPosition;
        }

        function generateCodes() {
            var codes = { placeId: vm.business.id, businessId: vm.business.businessId,
                numCodes: vm.codesQtt, startFrom: vm.startFrom, price: vm.codesPrice ? 0 : vm.business.price, expirationDays: vm.expiration};
            BusinessCodeService.generateMultipleCodes(codes).then(function(data) {
                vm.codesGenerated = data;
            })
        }

        function saveCode() {
            var codes = { placeId: vm.business.id, businessId: vm.business.businessId,
                code: vm.codeSKU,  price: vm.codesPrice2 ? 0 : vm.business.price, inUse: false, multiple: false, expirationDays: vm.expiration2 };
            BusinessCodeService.generateCode(codes).then(function(data) {
                vm.codesGenerated = new Array();
                vm.codesGenerated.push(vm.codeSKU);
            })

        }

        function showCodes(used) {
            BusinessCodeService.getCodes(vm.business.id, used).then(function(data) {
                if (used) {
                    vm.codesUsed = new Array();
                    angular.forEach(data, function(code) {
                        vm.codesUsed.push(code.code);
                    });
                } else {
                    vm.codesUnused = data;
                }
            });
        }

        function addUser(user) {
            BusinessPlaceUtils.addUserToPlace(vm.business.id, user.id).then(function(data){
                vm.users.push(user);
                vm.searchedUsers=[];
                vm.searchUser="";
            });
        };

        function removeUser(user) {
            BusinessPlaceUtils.removeUserFromPlace(vm.business.id, user.userId).then(function(data){
                for (var i=vm.users.length-1; i>=0; i--) {
                    if (vm.users[i].id==user.id) {
                        vm.users.splice(i, 1);
                    }
                }
            });
        }

        function changeUserRol(user) {
            BusinessPlaceUtils.updateUserRoleFromPlace(vm.business.id, user.userId, user.authority).then(function(data){

            });
        }

        $scope.$watch('vm.searchUser' , function() {
            if (vm.searchUser.length>3) {
                BusinessPlaceUtils.findAvailableUser(vm.searchUser).then(function(data) {
                    vm.searchedUsers = data;
                })
            }
        });


        function addProm(user) {
            BusinessPlaceUtils.addPromoterToPlace(vm.business.id, user.id).then(function(data){
                var prom = {userId: user.id, userLogin: user.login, userName: user.firstName, userEmail: user.email, partnerCode: "", phone: ""};
                vm.promoters.push(prom);
                vm.searchedProms=[];
                vm.searchProm="";
            });
        };

        function removeProm(user) {
            BusinessPlaceUtils.removePromoterFromPlace(vm.business.id, user.userId).then(function(data){
                for (var i=vm.promoters.length-1; i>=0; i--) {
                    if (vm.promoters[i].userId==user.userId) {
                        vm.promoters.splice(i, 1);
                    }
                }
            });
        }

        $scope.$watch('vm.searchProm' , function() {
            if (vm.searchProm.length>3) {
                BusinessPlaceUtils.findAvailablePromoter(vm.searchProm).then(function(data) {
                    vm.searchedProms = data;
                })
            }
        });

        function editPromoCode(prom, ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.image'))
                .textContent($translate.instant('vipWebApp.business.dialog.editTitle'))
                .placeholder($translate.instant('vipWebApp.business.dialog.description'))
                .initialValue(prom.partnerCode)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                var title = (result=="") ? "NO_TEXT" : result ;
                BusinessPlaceUtils.updatePromoCode(vm.business.id, prom.userId, title).then(function(data) {
                    prom.partnerCode = result;
                });
            }, function() {});
        }

        function editPromoPhone(prom, ev) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('vipWebApp.business.dialog.title.image'))
                .textContent($translate.instant('vipWebApp.business.dialog.editTitle'))
                .placeholder($translate.instant('vipWebApp.business.dialog.description'))
                .initialValue(prom.phone)
                .targetEvent(ev)
                .parent(angular.element(document.body))
                .ok("OK")
                .cancel("Cancel");

            $mdDialog.show(confirm).then(function(result) {
                var title = (result=="") ? "NO_TEXT" : result ;
                BusinessPlaceUtils.updatePromoPhone(vm.business.id, prom.userId, title).then(function(data) {
                    prom.phone = result;
                });
            }, function() {});
        }

        /**
         * Save business
         */
        function saveBusiness() {
            // Fix categories
            if (vm.selectedCategories.length > 0) {
                vm.business.categories = "#" + vm.selectedCategories.join().replace(new RegExp(',', 'g'), "#") + "#";
            } else {
                vm.business.categories = "";
            }

            // Validate Links
            if (validLinks()) {
                if (vm.business.id) {
                    updatePartyDays();
                    BusinessPlaceService.update(vm.business).$promise.then(function () {
                        gotoBusinesses();
                    });
                } else {
                    BusinessPlaceService.save(vm.business).$promise.then(function () {
                        gotoBusinesses();
                    });
                }
            }
        }

        function updatePartyDays () {
            var partys = [];
            angular.forEach(vm.partyDays, function(party) {
                if (party.updated) {
                    partys.push(party);
                }
            });
            if (partys.length>0) BusinessPlaceUtils.updatePartyDescriptions(partys).then(function (data) { });
        }

        function validLinks() {
            var front = [];
            var moreInfo = [];

            angular.forEach(vm.links, function(links){
               angular.forEach(links, function(link) {

                   if (link.frontIcon==FRONT_ICON_ENABLED) {
                       var tmp = {type: link.id, value: link.value, urlButton: link.imageUrl};
                       front[link.order-1] = tmp;
                   }
                   if (link.moreIcon==MORE_ICON_ENABLED) {
                       var tmp = {type: link.id, value: link.value, urlButton: link.imageUrl};
                       moreInfo.push(tmp);
                   }
               });
            });
            angular.forEach(vm.extraLinks, function(links){
                angular.forEach(links, function(link) {
                    if (link.moreIcon==MORE_ICON_ENABLED) {
                        var tmp = {type: link.id, value: link.value, urlButton: link.imageUrl, title: link.name};
                        moreInfo.push(tmp);
                    }
                });
            });

            vm.business.links.frontLinks = front;
            vm.business.links.moreInfo = moreInfo;

            return true;
        }

        /**
         * Go to businesses page
         */
        function gotoBusinesses()
        {
            $state.go('^');
        }

        /**
         * File added callback
         * Triggers when files added to the uploader
         *
         * @param file
         */
        function fileAdded(file)
        {
            // Prepare the temp file data for media list
            var uploadingFile = {
                id  : file.uniqueIdentifier,
                file: file,
                type: 'uploading'
            };

            // Append it to the media list
            vm.images.unshift(uploadingFile);
        }

        /**
         * Upload
         * Automatically triggers when files added to the uploader
         */
        function uploadLogo(file, field) {
            if (file.name!==undefined) {
                Upload.upload({
                    url: '/api/business-places/upload/logo',
                    data: {file: file, 'id': vm.business.id, 'type': field}
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    console.log('file ' + config.data.file.name + 'uploaded. Response: ' + data);
                });
            }
        }

        /**
         * Upload
         * Automatically triggers when files added to the uploader
         */
        function uploadImage(file, field) {
            if (file.name!==undefined) {
                vm.uploading = true;
                Upload.upload({
                    url: '/api/business-place-medias/upload/media',
                    data: {file: file, 'id': vm.business.id, 'type': vm.imageType}
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    if (data.id!=null) {
                        angular.forEach(vm.images, function(media) {
                            if (media.type == vm.imageType) {
                                media.position++;
                            }
                        });
                        vm.images.push(data);
                    }
                    vm.mediaFile = "";
                    vm.uploading = false;
                    checkMaxFiles();
                });
            }
        }

        /**
         * Checks if the given form valid
         *
         * @param formName
         */
        function isFormValid(formName)
        {
            if ( $scope[formName] && $scope[formName].$valid )
            {
                return $scope[formName].$valid;
            }
        }

        function toggleImage (item, list) {
            var idx = list.indexOf(item);
            if (idx > -1) {
                list.splice(idx, 1);
            }
            else {
                list.push(item);
            }
        }

        function existImage (item, list) {
            return list.indexOf(item) > -1;
        }


        function checkMaxFiles() {
            var total = 0;
            angular.forEach(vm.images, function (image) {
                if (image.type==vm.imageType) total++
            });

            angular.forEach(vm.imageTypes, function (type) {
                if (type.id == vm.imageType) {
                    vm.maxFiles = total>=type.max;
                }
            });
        }

        function imageByApp(imageType) {
            if  (imageType.apps.indexOf(0) >= 0) return true;
            else return imageType.apps.indexOf(vm.business.applicationId) >= 0;
        }
    }
})();
